<template>
  <div
    class="absolute top-0 left-0 z-30 h-screen w-screen bg-[#003D31] flex justify-center items-center overflow-hidden"
  >
    <img
      src="@/assets/images/miningWeek/MWLogo/mining-week-gif.gif"
      alt="logo"
      class="w-14"
    />
  </div>
</template>

<script>
export default {
  name: "MW2023OverlayLoading",
};
</script>
