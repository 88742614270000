<template>
  <MWBackground>
    <div class="flex justify-center w-full py-10 z-10 text-white">
      <div
        class="flex max-sm:flex-col w-full min-[1200px]:w-4/5 max-[1199px]:px-3 min-h-[60vh] gap-5"
      >
        <div
          class="grid grid-cols-2 sm:grid-cols-1 w-full sm:w-1/3 lg:w-1/5 h-fit gap-3 sm:text-nowrap"
        >
          <button
            @click="selectDay(1)"
            class="block w-full p-1 sm:p-1 sm:p-3 text-start"
            :class="
              selectedDay === 1
                ? 'bg-[#085A43]'
                : 'bg-[#009269] hover:bg-[#085A43]'
            "
          >
            <p class="uppercase font-bold">
              {{ lang === "mn" ? "Өдөр 1" : "Day 1" }}
            </p>
            <p class="text-xs">
              {{
                lang === "mn"
                  ? "Лхагва, 10-р сарын 02, 2024"
                  : "Wednesday, October 02, 2024"
              }}
            </p>
          </button>
          <button
            @click="selectDay(2)"
            class="block w-full p-1 sm:p-3 text-start"
            :class="
              selectedDay === 2
                ? 'bg-[#085A43]'
                : 'bg-[#009269] hover:bg-[#085A43]'
            "
          >
            <p class="uppercase font-bold">
              {{ lang === "mn" ? "Өдөр 2" : "Day 2" }}
            </p>
            <p class="text-xs">
              {{
                lang === "mn"
                  ? "Пүрэв, 10-р сарын 03, 2024"
                  : "Thursday, October 03, 2024"
              }}
            </p>
          </button>
          <button
            @click="selectDay(3)"
            class="block w-full p-1 sm:p-3 text-start"
            :class="
              selectedDay === 3
                ? 'bg-[#085A43]'
                : 'bg-[#009269] hover:bg-[#085A43]'
            "
          >
            <p class="uppercase font-bold">
              {{ lang === "mn" ? "Өдөр 3" : "Day 3" }}
            </p>
            <p class="text-xs">
              {{
                lang === "mn"
                  ? "Баасан, 10-р сарын 04, 2024"
                  : "Friday, October 04, 2024"
              }}
            </p>
          </button>
          <button
            @click="selectDay(4)"
            class="block w-full p-1 sm:p-3 text-start"
            :class="
              selectedDay === 4
                ? 'bg-[#085A43]'
                : 'bg-[#009269] hover:bg-[#085A43]'
            "
          >
            <p class="uppercase font-bold">
              {{ lang === "mn" ? "Өдөр 4" : "Day 4" }}
            </p>
            <p class="text-xs">
              {{
                lang === "mn"
                  ? "Бямба, 10-р сарын 05, 2024"
                  : "Saturday, October 05, 2024"
              }}
            </p>
          </button>
        </div>

        <!-- IMAGE -->
        <div class="w-full sm:w-2/3 lg:w-4/5">
          <img
            v-if="images[lang][selectedDay]"
            :src="images[lang][selectedDay]"
            alt="Agenda Image"
            class="w-full h-auto"
          />
        </div>
      </div>
    </div>
  </MWBackground>
</template>

<script>
import MWBackground from "@/components/MiningWeek/MW2024/MWBackground.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "MWAgenda",
  components: {
    MWBackground,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const selectedDay = ref(1);
    const selectDay = (item) => {
      selectedDay.value = item;
    };

    const images = ref({
      mn: {
        1: require("@/assets/images/miningWeek2024/agenda/agenda-eng-1.png"),
        2: require("@/assets/images/miningWeek2024/agenda/agenda-eng-2.png"),
        3: require("@/assets/images/miningWeek2024/agenda/agenda-eng-3.png"),
        4: require("@/assets/images/miningWeek2024/agenda/agenda-eng-4.png"),
      },
      en: {
        1: require("@/assets/images/miningWeek2024/agenda/agenda-eng-1.png"),
        2: require("@/assets/images/miningWeek2024/agenda/agenda-eng-2.png"),
        3: require("@/assets/images/miningWeek2024/agenda/agenda-eng-3.png"),
        4: require("@/assets/images/miningWeek2024/agenda/agenda-eng-4.png"),
      },
    });

    return { lang, selectedDay, selectDay, images };
  },
};
</script>
