<template>
  <main>
    <swiper
      :modules="modules"
      :slides-per-view="5"
      :autoplay="{ disableOnInteraction: true }"
      class="flex md:hidden py-3"
    >
      <swiper-slide v-for="(company, index) in members" :key="index">
        <a
          :href="company.link"
          target="_blank"
          class="flex items-center justify-center"
        >
          <img
            :src="company.logo"
            :alt="company.name"
            class="aspect-[3/2] w-2/3 object-contain mix-blend-color-burn sm:w-1/2"
          />
        </a>
      </swiper-slide>
    </swiper>

    <swiper
      :modules="modules"
      :slides-per-view="10"
      :autoplay="{ disableOnInteraction: true }"
      class="hidden md:flex py-3"
    >
      <swiper-slide v-for="(company, index) in members" :key="index">
        <a
          :href="company.link"
          target="_blank"
          class="flex items-center justify-center"
        >
          <img
            :src="company.logo"
            :alt="company.name"
            class="aspect-[3/2] w-2/3 object-contain mix-blend-color-burn sm:w-1/2"
          />
        </a>
      </swiper-slide>
    </swiper>
  </main>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

export default {
  name: "MWSponserSlide",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const members = [
      {
        name: "Erdenet Uildver",
        logo: require("@/assets/images/miningWeek2024/slide/1.erdenet-uildver.png"),
        link: "https://www.erdenetmc.mn",
      },
      {
        name: "Oyu Tolgoi",
        logo: require("@/assets/images/miningWeek2024/slide/2.oyu-tolgoi.png"),
        link: "https://www.ot.mn",
      },
      {
        name: "Energy Resources",
        logo: require("@/assets/images/miningWeek2024/slide/3.energy-resources.png"),
        link: "https://www.energyresources.mn",
      },
      {
        name: "Erdenes Tavan Tolgoi",
        logo: require("@/assets/images/miningWeek2024/slide/4.ETT.png"),
        link: "https://ett.mn",
      },
      {
        name: "Erdene Mongol",
        logo: require("@/assets/images/miningWeek2024/slide/5.erdene-mongol.png"),
        link: "https://erdene.com",
      },
      {
        name: "MEC",
        logo: require("@/assets/images/miningWeek2024/sponsors/Moenco.png"),
        link: "https://www.mec.mn",
      },
      {
        name: "South Gobi Sands",
        logo: require("@/assets/images/miningWeek2024/slide/7.south-gobi-sands.jpeg"),
        link: "https://www.southgobi.com/html/index.php",
      },
      {
        name: "Steppe Gold",
        logo: require("@/assets/images/miningWeek2024/sponsors/Stgo.png"),
        link: "https://steppegold.com",
      },
      {
        name: "Khan Altai",
        logo: require("@/assets/images/miningWeek2024/slide/9.khan-altai.png"),
        link: "https://khanaltai.mn",
      },
      {
        name: "Platinum Land",
        logo: require("@/assets/images/miningWeek2024/slide/10.Platinum-Land.png"),
        link: "https://www.platinumland.mn/eng",
      },
      {
        name: "Monpolymet",
        logo: require("@/assets/images/miningWeek2024/slide/11.monpolymet.png"),
        link: "https://en.monpolymet.mn",
      },
      {
        name: "Erdenes Mongol Negdel",
        logo: require("@/assets/images/miningWeek2024/slide/12.erdenes-mongol.png"),
        link: "https://www.erdenesmongol.mn/mn",
      },
      {
        name: "Erdenes Oyu Tolgoi",
        logo: require("@/assets/images/miningWeek2024/slide/13.EOT.png"),
        link: "https://eot.mn",
      },
      {
        name: "BTEG",
        logo: require("@/assets/images/miningWeek2024/slide/14.BTEG.png"),
        link: "",
      },
      {
        name: "Bayan Airag",
        logo: require("@/assets/images/miningWeek2024/slide/15.bayan-airag.png"),
        link: "https://austchammongolia.com/member/bayan-airag-exploration-llc/",
      },
      {
        name: "Usukh Zoos",
        logo: require("@/assets/images/miningWeek2024/slide/16.usukh-zoos.png"),
        link: "https://usukhzoos.mn",
      },
      {
        name: "Tsairt Mineral",
        logo: require("@/assets/images/miningWeek2024/slide/17.Tsairt-Mineral.png"),
        link: "https://www.tsairt.mn",
      },
      {
        name: "Rio Tinto",
        logo: require("@/assets/images/miningWeek2024/slide/18.Riotinto.png"),
        link: "https://www.riotinto.com",
      },
      {
        name: "Xanadu Mines",
        logo: require("@/assets/images/miningWeek2024/slide/19.XAM.svg"),
        link: "https://www.xanadumines.com",
      },
      {
        name: "Badrakh Energy",
        logo: require("@/assets/images/miningWeek2024/slide/20.Badrakh-Energy.png"),
        link: "https://badrakhenergy.com/en/home-en/",
      },
      {
        name: "Terra Energy",
        logo: require("@/assets/images/miningWeek2024/slide/21.terra-energy.png"),
        link: "https://www.terraenergy.mn",
      },
      {
        name: "Innova Mineral",
        logo: require("@/assets/images/miningWeek2024/slide/22.Innova-Mineral.png"),
        link: "https://www.instagram.com/innovamineral/",
      },
      {
        name: "Khurgatai Khairkhan",
        logo: require("@/assets/images/miningWeek2024/slide/23.Khurgatai-Khairkhan.png"),
        link: "https://www.khurgataikhairkhan.mn",
      },
      {
        name: "Khutul",
        logo: require("@/assets/images/miningWeek2024/slide/24.Khutul.png"),
        link: "https://khutulcement.mn/en/home/",
      },
      {
        name: "BN",
        logo: require("@/assets/images/miningWeek2024/slide/25.BN.png"),
        link: "",
      },
      {
        name: "Achit Ikht",
        logo: require("@/assets/images/miningWeek2024/slide/26.achit-ikht.png"),
        link: "https://achit-ikht.mn",
      },
      {
        name: "MERA",
        logo: require("@/assets/images/miningWeek2024/slide/27.MERA.png"),
        link: "https://mera.mn/en/home-page/",
      },
      {
        name: "Redpath",
        logo: require("@/assets/images/miningWeek2024/slide/28.Redpath.png"),
        link: "https://www.redpathmining.com/en/regions/asia",
      },
      {
        name: "Worley",
        logo: require("@/assets/images/miningWeek2024/slide/29.worley.png"),
        link: "https://www.worley.com/en/about-us/where-we-operate/mongolia",
      },
      {
        name: "Newcom.png",
        logo: require("@/assets/images/miningWeek2024/slide/30.Newcom.png"),
        link: "https://newcom.mn/?page_id=108",
      },
      {
        name: "Orica",
        logo: require("@/assets/images/miningWeek2024/slide/31.orica.png"),
        link: "https://www.orica.com/Locations/Australia--Pacific-and-Asia/mongolia",
      },
      {
        name: "Monros",
        logo: require("@/assets/images/miningWeek2024/slide/32.Monros.png"),
        link: "https://www.mongolros.mn",
      },
      {
        name: "MNREC",
        logo: require("@/assets/images/miningWeek2024/slide/33.MNREC.png"),
        link: "https://www.mnrec.mn",
      },
      {
        name: "Suuri tur",
        logo: require("@/assets/images/miningWeek2024/slide/34.Suuri-tur.png"),
        link: "https://www.suuritur.mn",
      },
      {
        name: "Gansu Trade",
        logo: require("@/assets/images/miningWeek2024/slide/35.Gabsu-trade.png"),
        link: "http://www.gszsd.com.cn/en/",
      },
      {
        name: "Bat Development",
        logo: require("@/assets/images/miningWeek2024/slide/36.Bat-development.png"),
        link: "",
      },
      {
        name: "Transwide",
        logo: require("@/assets/images/miningWeek2024/slide/37.transwide.png"),
        link: "https://www.facebook.com/transwidesolution/",
      },
      {
        name: "Barloworld",
        logo: require("@/assets/images/miningWeek2024/slide/38.Barloworld.jpg"),
        link: "https://www.barloworld.mn",
      },
      {
        name: "Erch Mining",
        logo: require("@/assets/images/miningWeek2024/slide/39.Erch-mining.png"),
        link: "https://erchmining.mn",
      },
      {
        name: "Shin Shin",
        logo: require("@/assets/images/miningWeek2024/slide/40.Shin-shin.png"),
        link: "https://www.facebook.com/p/Шинь-Шинь-ХХК-100057061045534/",
      },
      {
        name: "Aode",
        logo: require("@/assets/images/miningWeek2024/slide/41.Aode.png"),
        link: "https://aode.mn",
      },
      {
        name: "Boroogold",
        logo: require("@/assets/images/miningWeek2024/slide/42.boroogold.png"),
        link: "http://www.boroogold.mn",
      },
      {
        name: "Glogex",
        logo: require("@/assets/images/miningWeek2024/slide/43.Glogex.png"),
        link: "https://www.glogex.mn/mn",
      },
      {
        name: "Mandal Resources",
        logo: require("@/assets/images/miningWeek2024/slide/44.Mandal-Resource.png"),
        link: "https://opendatalab.mn/search/5699134",
      },
      {
        name: "Hera",
        logo: require("@/assets/images/miningWeek2024/slide/45.Hera.png"),
        link: "https://www.hera.mn",
      },
    ];

    return {
      members,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
};
</script>
