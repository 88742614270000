<template>
  <div class="loader"></div>
</template>

<script>
export default {
  name: "BarLoading",
};
</script>

<style scoped>
.loader {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat
    linear-gradient(#fff calc(50% - 10px), #0000 0 calc(50% + 10px), #fff 0);
  background: var(--c) 0% 100%, var(--c) 50% 100%, var(--c) 100% 100%;
  background-size: 20% calc(200% + 20px);
  animation: l4 1s infinite linear;
}

@keyframes l4 {
  33% {
    background-position: 0% 50%, 50% 100%, 100% 100%;
  }
  50% {
    background-position: 0% 0%, 50% 50%, 100% 100%;
  }
  66% {
    background-position: 0% 0%, 50% 0%, 100% 50%;
  }
  100% {
    background-position: 0% 0%, 50% 0%, 100% 0%;
  }
}
</style>
