<template>
  <div class="flex">
    <!-- bosoo nav -->
    <nav
      class="sticky top-0 bg-white h-screen border-r"
      :class="hiddenNavbar ? 'w-[4rem] px-3' : 'w-[20vw]'"
    >
      <div class="relative flex">
        <div
          v-if="!hiddenNavbar"
          class="flex flex-col justify-center items-center gap-1 w-1/2 h-16 p-2"
          :class="
            selectedPage === 'mining-mongolia'
              ? ''
              : 'shadow-inner shadow-gray-200 opacity-50'
          "
        >
          <img
            src="../../assets/images/miningMongolia/favicon.png"
            alt="logo-1"
            class="w-1/3"
          />
          <h6
            class="text-xs text-center"
            :class="
              selectedPage === 'mining-mongolia'
                ? 'text-green font-bold'
                : 'text-gray-300'
            "
          >
            MNMA
          </h6>
        </div>
        <div
          v-if="!hiddenNavbar"
          class="flex flex-col justify-center items-center gap-1 w-1/2 h-16 p-2"
          :class="
            selectedPage === 'mining-week'
              ? ''
              : 'shadow-inner shadow-gray-200 opacity-50'
          "
        >
          <img
            src="../../assets/images/miningWeek/MWLogo/favicon.png"
            alt="logo-2"
            class="w-1/3"
          />
          <h6
            class="text-xs text-center"
            :class="
              selectedPage === 'mining-week'
                ? 'text-[#00a375] font-bold'
                : 'text-gray-300'
            "
          >
            Mining Week
          </h6>
        </div>
        <div
          class="flex justify-center items-center"
          :class="hiddenNavbar ? 'relative h-16' : 'absolute inset-0'"
        >
          <button
            @click="selectPage()"
            class="relative bg-gray-300 rounded-full w-10 h-6 cursor-pointer"
          >
            <span
              class="absolute top-0.5 rounded-full w-5 h-5 transition-all duration-300 ease-in-out"
              :class="
                selectedPage === 'mining-mongolia'
                  ? 'left-0.5 bg-green'
                  : 'right-0.5 bg-[#00a375]'
              "
            ></span>
          </button>
        </div>
      </div>

      <MMAdminNavbar
        v-if="selectedPage === 'mining-mongolia'"
        :hiddenNavbar="hiddenNavbar"
      />
      <MWAdminNavbar v-else :hiddenNavbar="hiddenNavbar" />
    </nav>

    <!-- Хөндлөн navbar -->
    <div :class="hiddenNavbar ? 'w-full' : 'w-[80vw]'">
      <div
        class="sticky top-0 flex justify-between items-center w-full bg-white text-gray-500 pl-3 pr-5 py-2 border-b z-20"
      >
        <div class="flex items-center gap-5">
          <button
            @click="toggleNavbar()"
            class="cursor-pointer hover:text-blue-500"
          >
            <font-awesome-icon icon="fa-solid fa-bars" />
          </button>

          <div v-if="account" class="flex items-center gap-2">
            <div
              class="flex justify-center items-center h-8 w-8 rounded-full shadow-sm shadow-blue-500"
            >
              <span class="">{{
                account.first_name ? account.first_name.charAt(0) : ""
              }}</span>
            </div>
            <div class="block text-sm leading-4">
              <p class="text-blue-500">{{ account.first_name }}</p>
              <p class="text-[0.7rem]">{{ account.position }}</p>
            </div>
          </div>
        </div>
        <button @click="logout()" class="cursor-pointer hover:text-blue-500">
          <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
        </button>
      </div>

      <div class="bg-gray-100 p-3 space-y-3 min-h-[95vh] text-sm">
        <slot></slot>
      </div>

      <!-- Footer -->
      <footer class="w-full bg-gray-200 font-bold text-center text-xs p-1">
        <a href="https://bowsys.mn" target="_blank" class="hover:underline">
          &copy; BOW LLC - 2024
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import MMAdminNavbar from "./MiningMongolia/MMAdminNavbar.vue";
import MWAdminNavbar from "./MiningWeek/MWAdminNavbar";
import adminInstance from "@/lib/adminInstance";

export default {
  name: "AdminNavbar",
  components: {
    MMAdminNavbar,
    MWAdminNavbar,
  },
  setup() {
    const account = ref({});
    const router = useRouter();
    const route = useRoute();
    const isRoute = ref(route.path);

    watch(
      () => route.path,
      (newRoute) => {
        isRoute.value = newRoute;
      }
    );

    const hiddenNavbar = ref(false);
    const toggleNavbar = () => {
      hiddenNavbar.value = !hiddenNavbar.value;
    };

    const logout = () => {
      try {
        Cookies.remove("token");
        router.push("/admin/login");
      } catch (err) {
        return err;
      }
    };

    const selectedPage = ref(null);
    const selectPage = (newValue) => {
      if (newValue) {
        return (selectedPage.value = newValue);
      } else if (selectedPage.value === "mining-mongolia") {
        return (selectedPage.value = "mining-week");
      } else {
        return (selectedPage.value = "mining-mongolia");
      }
    };

    onMounted(async () => {
      selectedPage.value = isRoute.value.includes("week")
        ? "mining-week"
        : "mining-mongolia";

      try {
        const res = await adminInstance.get("/get/account");
        if (res.status === 200) {
          account.value = res.data.account;
        }
      } catch (err) {
        return err;
      }
    });

    return {
      hiddenNavbar,
      toggleNavbar,
      logout,
      account,
      selectPage,
      selectedPage,
    };
  },
};
</script>
