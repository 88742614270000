import { createRouter, createWebHistory } from "vue-router";
import { adminAuth } from "./lib/auth";
import Cookies from "js-cookie";

import NotFoundPage from "./pages/NotFoundPage.vue";
import AdminLogin from "./pages/admin/AdminLogin.vue";

// Mining Mongolia ********************************
import MMHomePage from "./pages/MiningMongolia/MMHomePage.vue";
import MMNewsList from "./pages/MiningMongolia/CategoryPages/MMNewsList.vue";
import MMStaticPage from "./pages/MiningMongolia/CategoryPages/MMStaticPage.vue";
import MMDetailNews from "./pages/MiningMongolia/CategoryPages/MMDetailNews.vue";
import MMProjectPage from "./pages/MiningMongolia/CategoryPages/MMProjectPage.vue";
import MMSelfAssessment from "./pages/MiningMongolia/CategoryPages/MMSelfAssessment.vue";
import MMStockData from "./pages/MiningMongolia/MSE/MMStockPage.vue";

// Mining Mongolia Admin ********************************
import MMDashboard from "./pages/admin/MiningMongolia/MMDashboard.vue";
import MMSocialLinks from "./pages/admin/MiningMongolia/MMSocialLinks.vue";
import MMAddNews from "./pages/admin/MiningMongolia/MMAddNews.vue";
import MMUpdateNews from "./pages/admin/MiningMongolia/MMUpdateNews.vue";
import MMWebCategories from "./pages/admin/MiningMongolia/MMWebCategories.vue";
import MMEditStatic from "./pages/admin/MiningMongolia/MMEditStatic.vue";
import MMOfficeRequest from "./pages/admin/MiningMongolia/MMOfficeRequest.vue";
import MMHomeslides from "./pages/admin/MiningMongolia/MMHomeSlides.vue";
import MMAccounts from "./pages/admin/MiningMongolia/MMAccounts.vue";
import MMUpdateAssessment from "./pages/admin/MiningMongolia/MMUpdateAssessment.vue";
import MMAssessmentResult from "./pages/admin/MiningMongolia/MMAssessmentResult.vue";
import MMDocumentPdf from "./pages/admin/MiningMongolia/MMDocumentPdf.vue";
import MMMiningVideo from "./pages/admin/MiningMongolia/MMMiningVideo.vue";

// Mining Week ********************************
import MWHomePage from "./pages/MiningWeek/MW2024/MWHomePage.vue";
import MWAboutPage from "./pages/MiningWeek/MW2024/MWAboutPage.vue";
import MWAgenda from "./pages/MiningWeek/MW2024/MWAgenda.vue";
import MWPresentation from "./pages/MiningWeek/MW2024/MWPresentation.vue";
import MWContactPage from "./pages/MiningWeek/MW2024/MWContactPage.vue";
import MWForm from "./pages/MiningWeek/MW2024/MWForm.vue";
import MW2023HomePage from "./pages/MiningWeek/MW2023/MW2023HomePage.vue";
import MW2023StaticPage from "./pages/MiningWeek/MW2023/MW2023StaticPage.vue";
import MW2023Presentation from "./pages/MiningWeek/MW2023/MW2023Presentation.vue";

// Mining Week Admin ********************************
import MWWebCategories from "./pages/admin/MiningWeek/MWWebCategories.vue";
import MWSocialLink from "./pages/admin/MiningWeek/MWSocialLink.vue";
import MWUpdateFooter from "./pages/admin/MiningWeek/MWUpdateFooter.vue";
import MWUpdateHome from "./pages/admin/MiningWeek/MWUpdateHome.vue";
import MWAddNews from "./pages/admin/MiningWeek/MWAddNews.vue";
import MWUpdateNews from "./pages/admin/MiningWeek/MWUpdateNews.vue";
import MWRegistration from "./pages/admin/MiningWeek/MWRegistration.vue";
import MWNewRegistration from "./pages/admin/MiningWeek/MWNewRegistration.vue";
import MWEventPresenters from "./pages/admin/MiningWeek/MWEventPresenters.vue";

const miningMongoliaRoutes = [
  {
    path: "/mm",
    name: "MMHomePage",
    component: MMHomePage,
  },
  {
    path: "/mm/category/:cid",
    name: "MMStaticPage",
    component: MMStaticPage,
  },
  {
    path: "/mm/blog/:cid=:page",
    name: "MMNewsList",
    component: MMNewsList,
  },
  {
    path: "/mm/detail/:id",
    name: "MMDetailNews",
    component: MMDetailNews,
  },
  {
    path: "/mm/page/:cid",
    name: "MMProjectPage",
    component: MMProjectPage,
  },
  {
    path: "/mm/self-assessment",
    name: "MMSelfAssessment",
    component: MMSelfAssessment,
  },
  {
    path: "/mm/trade",
    name: "MMStockData",
    component: MMStockData,
  },
];

const miningWeekRoutes = [
  {
    path: "/mw",
    name: "MWHomePage",
    component: MWHomePage,
  },
  {
    path: "/mw/about",
    name: "MWAboutPage",
    component: MWAboutPage,
  },
  {
    path: "/mw/agenda",
    name: "MWAgenda",
    component: MWAgenda,
  },
  {
    path: "/mw/presentation",
    name: "MWPresentation",
    component: MWPresentation,
  },
  {
    path: "/mw/contact",
    name: "MWContactPage",
    component: MWContactPage,
  },
  {
    path: "/mw/register",
    name: "MWForm",
    component: MWForm,
  },
  {
    path: "/mw/2023",
    name: "MW2023HomePage",
    component: MW2023HomePage,
  },
  {
    path: "/mw/2023/presentation",
    name: "MW2023Presentation",
    component: MW2023Presentation,
  },
  {
    path: "/mw/2023/category/:cid",
    name: "MW2023StaticPage",
    component: MW2023StaticPage,
  },
];

const adminRoutes = [
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin/dashboard",
    name: "MMDashboard",
    component: MMDashboard,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/link",
    name: "MMWebUrl",
    component: MMWebCategories,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/add-news",
    name: "MMAddNews",
    component: MMAddNews,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/update-news",
    name: "MMUpdateNews",
    component: MMUpdateNews,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/social-links",
    name: "MMSocialLinks",
    component: MMSocialLinks,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/edit-static/:cid",
    name: "MMEditStatic",
    component: MMEditStatic,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/request-list",
    name: "MMOfficeRequest",
    component: MMOfficeRequest,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/slides",
    name: "MMHomeSlides",
    component: MMHomeslides,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/accounts",
    name: "MMAccounts",
    component: MMAccounts,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/update-assessment",
    name: "MMUpdateAssessment",
    component: MMUpdateAssessment,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/assessment-result",
    name: "MMAssessmentResult",
    component: MMAssessmentResult,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/document",
    name: "MMDocumentPdf",
    component: MMDocumentPdf,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/video",
    name: "MMMiningVideo",
    component: MMMiningVideo,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
];

const adminMWRoutes = [
  {
    path: "/admin/week/link",
    name: "MWWebUrl",
    component: MWWebCategories,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/social-link",
    name: "MWSocialLink",
    component: MWSocialLink,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/footer",
    name: "MWUpdateFooter",
    component: MWUpdateFooter,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/home",
    name: "MWUpdateHome",
    component: MWUpdateHome,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/add-news",
    name: "MWAddNews",
    component: MWAddNews,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/update-news",
    name: "MWUpdateNews",
    component: MWUpdateNews,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/register",
    name: "MWRegistration",
    component: MWRegistration,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/new-register",
    name: "MWNewRegistration",
    component: MWNewRegistration,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/presenters",
    name: "MWEventPresenters",
    component: MWEventPresenters,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/:pathMatch(.*)*", name: "Page404", component: NotFoundPage },
    ...miningMongoliaRoutes,
    ...miningWeekRoutes,
    ...adminRoutes,
    ...adminMWRoutes,
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.path.includes("/mm")) {
    document.title = "Монголын Уул Уурхайн Үндэсний Ассоциаци";
  } else if (to.path.includes("/mw")) {
    document.title = "Mining Week";
  }

  if (to.path === "/admin" || to.path === "/admin/") {
    next("/admin/login");
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
    const authenticated = await adminAuth();
    if (authenticated) {
      if (to.path === "/admin/login") {
        next("/admin/dashboard");
        return;
      }
      next();
      return;
    }

    next("/admin/login");
  } else {
    if (to.path === "/admin/login") {
      if (Cookies.get("token")) {
        next("/admin/dashboard");
        return;
      }
    }
    next();
  }
});

export default router;
