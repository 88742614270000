<template>
  <main class="flex flex-col items-center justify-center">
    <section
      class="relative w-full overflow-hidden text-sm text-white h-[20vh] sm:h-[80vh]"
    >
      <div class="relative w-screen h-[20vh] sm:h-[80vh]">
        <iframe
          src="https://www.youtube.com/embed/GkZ3ZmtqTe8?autoplay=1&mute=1&loop=1&playlist=GkZ3ZmtqTe8&controls=0&modestbranding=1&iv_load_policy=3&rel=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          class="absolute top-[-15%] left-[-10%] w-[120%] h-[135%] overflow-hidden"
        ></iframe>
      </div>
      <div
        class="absolute bottom-0 left-0 h-2/3 w-full bg-gradient-to-t from-[#001F3D] to-transparent"
      ></div>
      <div class="overlay absolute top-0 left-0 w-full h-full"></div>
      <div
        class="absolute bottom-0 sm:bottom-24 left-0 flex justify-center items-center w-full"
      >
        <div class="w-full min-[1200px]:w-4/5 max-[1199px]:px-5">
          <h3 class="text-base sm:text-xl lg:text-2xl mb-3">
            Ulaanbaatar, Mongolia Oct 02-05
          </h3>
          <h1 class="font-bold text-2xl md:text-4xl lg:text-7xl uppercase">
            Mining Week 2024
          </h1>
        </div>
      </div>
    </section>

    <MWBackground>
      <section class="w-full min-[1200px]:w-4/5 max-[1199px]:px-3 my-10 z-10">
        <div
          class="bg-white bg-opacity-15 rounded-3xl text-white p-5 lg:p-14 space-y-8"
        >
          <MWOrganiserLogos
            v-motion-slide-visible-once-bottom
            class="flex items-center justify-center w-full"
          />

          <p
            v-if="lang === 'mn'"
            v-motion-fade-visible-once
            :duration="500"
            class="text-sm sm:text-base text-justify"
          >
            Эрхэм хүндэт ноёд хадагтай нараа <br />
            <br />
            Монгол улсын Эрдэс баялгийн салбар нь ДНБ-ий 25 гаруй хувь, улсын
            нэгдсэн төсвийн орлогын 30 гаруй хувь, гадаадын шууд хөрөнгө
            оруулалтын 73 орчим хувь, нийт экспортын 92 гаруй хувийг дангаар
            бүрдүүлдэг эдийн засгийн тулгуур салбар бөгөөд тус салбарын
            тогтвортой байдал нь манай эдийн засгийн тогтвортой байдалтай шууд
            хамааралтай байдаг.<br />
            <br />
            Иймд МУУҮА нь Аж үйлдвэр, эрдэс баялгийн яамтай хамтран Монгол улсын
            эрдэс баялгийн салбарыг гадаадын хөрөнгө оруулагч нарт сурталчлах,
            тус салбар дахь гадаадын хөрөнгө оруулалтыг нэмэгдүүлэх зорилгоор
            “Уул уурхайн долоо хоног” арга хэмжээг 2022 оноос эхлэн уламжлал
            болгон зохион байгуулан ажиллаж байна.<br />
            <br />
            2023 онд зохион байгуулсан “Уул уурхайн долоо хоног” арга хэмжээг
            Эрдэс баялгийн салбар дахь төрийн бодлого, шийдвэр, хууль эрх зүйн
            орчин, геологи эрэл хайгуул, хариуцлагатай уул уурхай, нүүрс, газрын
            тос, өнгөт металл, аж үйлдвэржилт, далд уурхай, өндөр технологийн
            түүхий эд зэрэг сэдэвчилсэн 20 хурал, уулзалт хэлбэрээр зохион
            байгуулсан бөгөөд тус арга хэмжээнд Австрали, Австри, Канад, АНУ,
            БНСУ, БНХАУ, ОХУ, ИБУИНВУ, Герман, Энэтхэг, Япон, Финлянд, ӨАБНУ,
            Арабын нэгдсэн Эмират улс, Франц, Испани, Пакистан зэрэг 20 орчим
            улсын 300 гаруй зочин төлөөлөгч, хөрөнгө оруулагчид хүрэлцэн ирж,
            төр, хувийн хэвшил, иргэний нийгмийн байгууллага, иргэдийн төлөөлөл
            болох 5000 орчим (давхардсан тоогоор) зочид төлөөлөгч оролцсон.<br />
            <br />
            2024 онд тус арга хэмжээг дэлхийн эрдэс баялгийн салбарт тренд болж
            буй чухал ашигт малтмал, хариуцлагатай уул уурхай, аж үйлдвэржилт
            болон уул уурхайн санхүүжилт гэсэн үндсэн сэдвийн хүрээнд зохион
            байгуулах гэж байгаагаа зарлахад таатай байна. Олон улсын хөрөнгө
            оруулагч, зөвлөхүүд болон мэргэжилтнүүдийг нэг дор хуран цуглуулж,
            уул уурхайн салбарын шинэчлэл, инновацыг танилцуулан тус салбарын
            ирээдүйн чиг хандлагыг тодорхойлоход өндөр ач холбогдолтой
            байдгаараа “Уул уурхайн долоо хоног” арга хэмжээ нь онцлогтой
            байдаг.<br />
            <br />
            Дөрвөн хоногийн турш тогтвортой уул уурхайн ирээдүйн болон хөгжлийг
            чиг хандлагыг тодорхойлж, чухал ашигт малтмалын зах зээл, эрэлт
            хэрэгцээг хэлэлцэх “Уул уурхайн долоо хоног 2024 болон Minepro 2024”
            арга хэмжээ зохион байгуулагдах болно.
          </p>

          <p
            v-else
            v-motion-fade-visible
            :delay="1000"
            class="text-sm sm:text-base text-justify"
          >
            Dear Sir/Madam, <br />
            <br />
            The MONGOLIAN NATIONAL MINING ASSOCIATION cordially invites you to
            attend at MININGWEEK 2024 and MINEPRO 2024 - International Mining
            Exhibition & Convention scheduled to take place from October 2 to 5,
            2024 in Ulaanbaatar, Mongolia.<br />
            <br />
            Mongolia’s mineral resources industry stands as the backbone of its
            economy contributing over 25% to GDP more than 30% to the state’s
            general budget revenue about 73% to foreign direct investment and
            over 92% to total exports. <br />
            This industry’s significance is directly tied to the current
            economic landscape. Consequently, the Mongolian National Mining
            Association, in collaboration with the Mongolian Ministry of
            Industry and Mineral Resources has been organising the “MiningWeek”
            event since 2022.<br />
            <br />
            The primary objective is to promote Mongolia’s mineral resources
            sector to foreign investors and attract foreign investment. The
            “MiningWeek” event in 2023 focused on government policies,
            decisions, the legal framework in the mineral resources sector,
            geological exploration, responsible mining, coal, oil, non-ferrous
            metals, industrialisation, underground mining, and high-tech
            applications covering around 20 thematic conferences and
            meetings.<br />
            <br />
            Over 300 guests from 20 countries including Australia, Austria,
            Canada, the USA, South Korea, China, Russia, the United Kingdom,
            Germany, India, Japan, Finland, South Africa, the United Arab
            Emirates, France, Spain and Pakistan attended. Approximately 5,000
            participants representing the government, private sector, civil
            society organisations and citizens actively participated. <br />
            In 2024, we are excited to announce the upcoming “MiningWeek 2024”
            event focusing on the pivotal themes of critical minerals,
            responsible mining, industrialization and mining financing which
            currently stand out as significant trends in the global mineral
            resources sector.<br />
            <br />
            As part of this initiative, we are proud to present the “MinePro
            2024” exhibition a collaborative effort that will take place during
            the “MiningWeek 2024”. This exhibition promises to showcase
            cutting-edge advancements and innovations in the mining industry
            bringing together professionals and experts from around the world.
            <br />
            Join us for four days dedicated to exploring the future of
            sustainable mining and the vital role of critical minerals in
            shaping the trajectory of the industry. <br /><br />
            We look forward to welcoming you to MIningWEEK & MINEPRO 2024
          </p>

          <span class="flex justify-end text-sm sm:text-base">
            <p>
              {{ lang === "mn" ? "Г.Батцэнгэл" : "Battsengel Gotov" }} <br />
              <img
                src="../../../assets/images/miningWeek2024/battsengel-sign-white.png"
                alt="signature"
              />
              {{
                lang === "mn"
                  ? "Монголын уул уурхайн үндэсний"
                  : "Chairman of the Board of the"
              }}
              <br />
              {{
                lang === "mn"
                  ? "ассоциацийн Зөвлөлийн дарга"
                  : "Mongolian National Mining Association"
              }}
            </p>
          </span>

          <div class="flex justify-center">
            <router-link
              to="/mw/register"
              class="bg-[#009269] w-1/2 sm:w-1/3 p-2 uppercase text-center hover:scale-105"
            >
              {{ lang === "mn" ? "Бүртгүүлэх" : "Register" }}
            </router-link>
          </div>
        </div>
      </section>

      <section
        class="flex flex-col items-center w-full min-[1200px]:w-4/5 max-[1199px]:px-5 mt-28 text-white z-10"
      >
        <h2>{{ lang === "mn" ? "Зохион байгуулагч" : "Organiser" }}</h2>
        <div
          v-motion-fade-visible-once
          class="flex max-md:flex-col justify-center items-center gap-5 md:gap-10 min-[1100px]:gap-16 mt-4"
        >
          <img
            src="../../../assets/images/miningWeek2024/sponsors/MIMR.png"
            alt="logo-1"
            class="h-[4.5rem] object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/MRPAM.png"
            alt="logo-2"
            class="h-20 object-contain"
          />
          <img
            src="../../../assets/images/miningWeek/spons-logo-2.png"
            alt="logo-3"
            class="h-20 object-contain"
          />
        </div>
      </section>

      <section
        class="flex flex-col items-center w-full min-[1200px]:w-4/5 max-[1199px]:px-5 mt-20 text-white z-10"
      >
        <h2>Platinum sponsor</h2>
        <div
          v-motion-fade-visible-once
          class="flex max-md:flex-col md:max-lg:grid md:grid-cols-2 justify-center items-center gap-5 md:gap-10 min-[1100px]:gap-12 mt-4"
        >
          <img
            src="../../../assets/images/miningWeek2024/sponsors/energy-resources-en.png"
            alt="logo-1"
            class="h-9 object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/ett.png"
            alt="logo-2"
            class="h-12 object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/tdb.png"
            alt="logo-3"
            class="h-12 object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/monnis.png"
            alt="logo-4"
            class="h-10 object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/shunhlai.png"
            alt="logo-6"
            class="h-10 object-contain"
          />
        </div>
      </section>

      <section
        class="flex flex-col items-center w-full min-[1200px]:w-4/5 max-[1199px]:px-5 mt-20 text-white z-10"
      >
        <h2>Diamond sponsor</h2>
        <div
          v-motion-fade-visible-once
          class="flex max-md:flex-col md:max-[1199px]:grid md:grid-cols-2 lg:grid-cols-4 justify-center items-center gap-3 md:gap-8 mt-4"
        >
          <img
            src="../../../assets/images/miningWeek2024/sponsors/erdener_mining_corporation.png"
            alt="logo-1"
            class="h-10 object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/Stgo.png"
            alt="logo-2"
            class="h-16 object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/badrakh_energy_white.png"
            alt="logo-3"
            class="h-16 object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/mera.png"
            alt="logo-4"
            class="h-16 object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/aode.png"
            alt="logo-5"
            class="h-14 object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/transwest.png"
            alt="logo-6"
            class="h-12 object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/Petrovis.png"
            alt="logo-7"
            class="h-12 object-contain"
          />
        </div>
      </section>

      <section
        class="flex flex-col items-center w-full min-[1200px]:w-4/5 max-[1199px]:px-5 mt-20 text-white z-10"
      >
        <h2>Gold sponsor</h2>
        <div
          v-motion-fade-visible-once
          class="flex max-md:flex-col md:max-lg:grid md:grid-cols-2 lg:grid-cols-4 justify-center items-center gap-5 md:gap-10 min-[1100px]:gap-16 mt-4"
        >
          <img
            src="../../../assets/images/miningWeek2024/sponsors/erdene-mongol.png"
            alt="logo-1"
            class="h-[3.5rem] object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/Moenco.png"
            alt="logo-2"
            class="h-[3.5rem] object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/MNREC.png"
            alt="logo-3"
            class="h-[3.5rem] object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/asian-battery-metals.png"
            alt="logo-4"
            class="h-[3.2rem] object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/boroogold_en.png"
            alt="logo-5"
            class="h-[3.5rem] object-contain"
          />
          <img
            src="../../../assets/images/miningWeek2024/sponsors/mak.png"
            alt="logo-6"
            class="h-[3.5rem] object-contain"
          />
        </div>
      </section>

      <section
        class="flex flex-col items-center w-full min-[1200px]:w-4/5 max-[1199px]:px-5 mt-20 mb-32 text-white z-10"
      >
        <h2>Exclusive Media partner</h2>
        <div
          v-motion-fade-visible-once
          class="flex max-md:flex-col justify-center items-center gap-5 md:gap-10 min-[1100px]:gap-16 mt-4"
        >
          <img
            src="../../../assets/images/miningWeek2024/sponsors/bloomberg.png"
            alt="logo-1"
            class="h-8 object-contain"
          />
        </div>
      </section>
    </MWBackground>
  </main>
</template>

<script>
import MWBackground from "@/components/MiningWeek/MW2024/MWBackground";
import MWOrganiserLogos from "@/components/MiningWeek/MW2024/MWOrganiserLogos";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "MWHomePage",
  components: {
    MWBackground,
    MWOrganiserLogos,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    return { lang };
  },
};
</script>

<style scoped>
.overlay {
  background-image: url("@/assets/images/miningWeek/diagonalstripe-black.png");
  opacity: 0.6;
}
</style>
