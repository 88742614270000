<template>
  <MWBackground>
    <main class="flex justify-center w-full z-10 text-white">
      <div
        class="w-full min-[1200px]:w-4/5 min-h-[150vh] max-[1199px]:px-5 my-10"
      >
        <div class="w-full sm:w-2/3 mb-20">
          <img
            v-if="lang === 'mn'"
            src="../../../assets/images/miningWeek2024/logo-white-side-mon.png"
            alt="logo-mon"
            class="w-full sm:w-1/2 object-fit"
          />
          <img
            v-else
            src="../../../assets/images/miningWeek2024/logo-white-side-eng.png"
            alt="logo-eng"
            class="w-full sm:w-1/2 object-fit"
          />

          <p
            v-if="lang === 'mn'"
            v-motion-fade-visible-once
            :delay="500"
            class="text-sm text-justify mt-7"
          >
            Монголын Уул Уурхайн Үндэсний Ассоциаци (МУУҮА) нь 1994 онд үүсгэн
            байгуулагдсан, эрдэс баялгийн салбарт үйлдвэрлэл, үйлчилгээ явуулдаг
            аж ахуйн нэгж, байгууллагуудыг гишүүнчлэлээр баталгаажуулсан, хараат
            бус үйл ажиллагаа явуулдаг, ашгийн бус байгууллага юм.
            <br /><br />
            Одоогийн байдлаар МУУҮА нь Аж үйлдвэр, эрдэс баялгийн яам, Байгаль
            орчин, уур амьсгалын өөрчлөлтийн яам, Монголын Хөрөнгийн Бирж,
            МОНЦАМЭ агентлаг, Монгол Улсын Их Сургууль, Канад улсын хайгуул,
            олборлогчдын холбоо (PDAC) болон Канадын уул уурхайн холбоо зэрэг
            нэр нөлөө бүхий байгууллагуудтай хамтран ажиллаж байна. Дээрх хамтын
            ажиллагааг түшиглэн “Miningweek & MinePro 2024” олон улсын уул
            уурхайн үзэсгэлэн, чуулга уулзалт, Олон улсын уул уурхайн хөрөнгө
            оруулагчдын форум зэрэг арга хэмжээнүүдийг зохион байгуулж байгаа нь
            эрдэс баялгийн салбар дахь хувийн хэвшлийн хөрөнгө оруулалтыг
            дэмжих, тус салбарын нэр хүндийг сайжруулах чухал ач холбогдолтой
            байгаад таатай байна.
            <br /><br />
            МУУҮА нь төрийн болон төрийн бус байгууллагууд, олон улсын хөгжлийн
            агентлагууд, санхүүгийн байгууллагуудтай хамтран Монгол улсад
            “Хариуцлагатай уул уурхай”-г хөгжүүлэх үндсэн зорилгын хүрээнд олон
            санаачилгуудыг дэвшүүлэн хэрэгжүүлж хариуцлагатай уул уурхайн үйл
            ажиллагааг орон даяар түгээн дэлгэрүүлэх, стратегийн хамтын
            ажиллагааг бэхжүүлэх, салбарын тулгамдсан асуудлыг шийдэх замаар уул
            уурхайн салбарын тогтвортой байдлыг хангахыг чармайн ажилласаар
            байна.
            <br /><br />
            <span class="font-bold text-base">Үйл ажиллагааны чиглэл</span
            ><br />
            Байгуулагдсан цагаасаа эхлэн МУУҮА нь дараах чиг үүргийн хүрээнд үйл
            ажиллагаа явуулж байна.
            <br /><br />
          </p>

          <p
            v-else
            v-motion-fade-visible-once
            :delay="500"
            class="text-sm text-justify mt-7"
          >
            Founded in 1994, the Mongolian National Mining Association (MNMA) is
            dedicated to safeguarding the lawful interests and benefits of its
            members. It aims to influence state policies provide the public with
            the latest and most comprehensive information on the minerals
            industry and foster effective collaborations.
            <br /><br />
            Currently, the MNMA focuses its collaborations with prominent
            entities such as the Ministry of Industry and Mineral Resources,
            Ministry of Environment and Tourism, Mongolian Stock Exchange,
            Montsame news agency, National University of Mongolia, the
            Prospectors & Developers Association of Canada (PDAC) and Mining
            Association of Canada.
            <br /><br />
            These partnerships are crucial in promoting private investments and
            enhancing the image of Mongolia’s minerals sector through
            initiatives like the MinePro International Quarrying and Mining
            Exhibition & Convention, International Mining Investors Forum. A
            cornerstone of MNMA’s efforts is the Responsible Mining Initiative,
            supported by a broad coalition of local NGOs, international
            development agencies, and financial institutions and ministries.
            <br /><br />
            This initiative serves as a key platform for promoting responsible
            mining practices across Mongolia. Through these strategic
            collaborations and initiatives, MNMA continues to advocate for a
            dynamic and authentic mineral sector in Mongolia, addressing the
            challenges of today while capturing the opportunities of tomorrow.
          </p>

          <ul
            v-if="lang === 'mn'"
            v-motion-fade-visible-once
            :delay="500"
            class="list-disc ml-10 text-sm"
          >
            <li>
              Гишүүн байгууллагуудынхаа хууль ёсны ашиг сонирхлыг хамгаалах,
              төлөөлөх;
            </li>
            <li>Хариуцлагатай уул уурхайг хөгжүүлэх, нутагшуулах;</li>
            <li>
              Уул уурхайн салбарын тогтвортой байдлыг хангах, тус салбар дахь
              гадаадын хөрөнгө оруулалтыг нэмэгдүүлэх;
            </li>
            <li>
              Олон нийтэд салбарын сүүлийн үеийн мэдээ мэдээллийг цогц байдлаар
              хүргэх;
            </li>
            <li>Үр дүнтэй хамтын ажиллагааг дэмжих.</li>
          </ul>
        </div>
      </div>
    </main>
  </MWBackground>
</template>

<script>
import MWBackground from "@/components/MiningWeek/MW2024/MWBackground.vue";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "MWAboutPage",
  components: {
    MWBackground,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    return { lang };
  },
};
</script>
