<template>
  <AdminNavbar>
    <!-- list -->
    <div class="bg-white rounded shadow">
      <h6
        class="text-base font-bold border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        Үнэлгээнд хариулсан хэрэглэгчид
      </h6>

      <div v-if="!isLoading && respondents.length > 0" class="p-3 space-y-5">
        <div class="flex justify-between items-center text-xs">
          <div class="space-x-3">
            <span>Хуудас бүрт:</span>
            <select
              name="dataPerPage"
              id="dataPerPage"
              class="border rounded p-1 text-blue-500"
              v-model="dataPerPage"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>

          <span>Нийт үнэлгээ өгсөн тоо: {{ totalRespondentCount }} </span>

          <div class="flex items-center gap-3">
            <span>Нийт хуудас: {{ totalPages }} </span>
            <PaginationCart
              :totalPages="totalPages"
              :modelValue="currentPage"
              :style="{
                color: 'blue-500',
                height: 7,
                width: 7,
                fontSize: 'xs',
              }"
              @page-changed="onPageChanged"
            />
          </div>
        </div>

        <hot-table :settings="hotSettings" :data="respondents"></hot-table>
      </div>

      <div
        v-else-if="!isLoading && respondents.length === 0"
        class="flex justify-center items-center h-[50vh]"
      >
        Хариулт хэсэг хоосон байна
      </div>

      <div v-else class="flex justify-center items-center h-[50vh]">
        <spin-loading :color="'rgb(59 130 246)'" />
      </div>
    </div>
  </AdminNavbar>

  <!-- ANSWERS -->
  <div
    v-if="isDetailsShowing"
    class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[200] h-full w-full"
  >
    <div
      class="h-[90vh] w-3/4 bg-white rounded p-5 space-y-5 overflow-x-scroll"
    >
      <div class="flex justify-end">
        <button
          @click="closeDetails()"
          class="flex justify-center items-center bg-blue-200 h-8 w-8 rounded-full text-sm text-white hover:bg-blue-300"
        >
          <font-awesome-icon icon="fa-solid fa-x" />
        </button>
      </div>

      <div
        v-if="!isAnswerLoading && assessmentAnswer.length > 0"
        class="space-y-5"
      >
        <div class="flex justify-between items-center text-sm">
          <p>
            Нийт хувь: <span class="font-bold">{{ totalPercentage }}%</span>
          </p>
          <p>Нийт хариулт: {{ totalCount }}</p>
          <p>Нийт тийм (<span class="text-green">✓</span>): {{ totalYes }}</p>
          <p>Нийт үгүй (<span class="text-red-500">✗</span>): {{ totalNo }}</p>
        </div>
        <table
          v-for="(answer, mIndex) in assessmentAnswer"
          :key="mIndex"
          class="w-full"
        >
          <thead class="bg-slate-300 text-black text-sm">
            <tr>
              <th class="border border-slate-300">
                {{ answer.mainIndex }}
              </th>
              <th class="border border-slate-300 uppercase">
                {{ answer.mainTitle }}
              </th>
              <th class="border border-slate-300">Тийм (1)</th>
              <th class="border border-slate-300">Үгүй (0)</th>
              <th class="border border-slate-300">
                Нотлох эх үүсвэр байгаа эсэх
              </th>
            </tr>
          </thead>

          <tbody class="text-xs">
            <template
              v-for="(question, qIndex) in answer.questions"
              :key="qIndex"
            >
              <!-- Parent Question -->
              <tr class="bg-slate-100">
                <td class="p-2 border border-slate-300">
                  {{ question.cid }}
                </td>
                <td class="p-2 border border-slate-300">
                  {{ question.question }}
                </td>
                <td class="border border-slate-300"></td>
                <td class="border border-slate-300"></td>
                <td class="border border-slate-300"></td>
              </tr>

              <!-- Child Questions -->
              <tr
                v-for="(childQuestion, cIndex) in question.children"
                :key="cIndex"
              >
                <td class="p-2 border border-slate-300">
                  {{ childQuestion.cid }}
                </td>
                <td class="p-2 border border-slate-300">
                  {{ childQuestion.question }}
                </td>
                <td
                  class="border border-slate-300"
                  :class="childQuestion.answer === true ? 'text-green' : ''"
                >
                  <div class="flex justify-center items-center">
                    {{ childQuestion.answer === true ? "✓" : "" }}
                  </div>
                </td>
                <td
                  class="border border-slate-300"
                  :class="childQuestion.answer === false ? 'text-red-500' : ''"
                >
                  <div class="flex justify-center items-center">
                    {{ childQuestion.answer === false ? "✗" : "" }}
                  </div>
                </td>
                <td
                  class="border border-slate-300"
                  :class="
                    childQuestion.proof === true ? 'text-green' : 'text-red-500'
                  "
                >
                  <div class="flex justify-center items-center">
                    {{
                      childQuestion.proof === true
                        ? "✓"
                        : childQuestion.proof === false
                        ? "✗"
                        : ""
                    }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div
        v-else-if="!isAnswerLoading && assessmentAnswer.length === 0"
        class="flex justify-center items-center h-full text-xl text-slate-500 font-bold"
      >
        Хариулт олдсонгүй.
      </div>

      <div v-else class="flex justify-center items-center h-full">
        <spin-loading :color="'rgb(59 130 246)'" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import adminInstance from "@/lib/adminInstance";
import AdminNavbar from "@/components/admin/AdminNavbar";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";

registerAllModules();

export default {
  name: "MMAssessmentResult",
  components: {
    AdminNavbar,
    SpinLoading,
    PaginationCart,
    HotTable,
  },
  setup() {
    const isLoading = ref(false);
    const respondents = ref([]);
    const dataPerPage = ref(10);
    const totalRespondentCount = ref(null);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const fetchAssessmentRespondents = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get(
          `/get/assessment-respondents?page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        respondents.value = res.data.respondents;
        totalPages.value = res.data.totalPages;
        totalRespondentCount.value = res.data.total;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchAssessmentRespondents();
    });

    watch([() => dataPerPage.value], () => {
      currentPage.value = 1;
      fetchAssessmentRespondents();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchAssessmentRespondents();
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center items-center p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: [
        "Байгууллагын нэр",
        "И-мэйл хаяг",
        "Утасны дугаар",
        "Үнэлгээнд оролцсон огноо",
        "Үйлдэл",
      ],
      columns: [
        {
          data: "name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "email",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "phone_number",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "created_at",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          renderer: function (inst, td, row) {
            const respondent = respondents.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                  <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                      <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 448c0 35.3 28.7 64 64 64l160 0 0-128c0-17.7 14.3-32 32-32l128 0 0-288c0-35.3-28.7-64-64-64L64 0C28.7 0 0 28.7 0 64L0 448zM171.3 75.3l-96 96c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l96-96c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zm96 32l-160 160c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l160-160c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zM384 384l-128 0 0 128L384 384z"/></svg>
                      <span>Дэлгэрэнгүй</span>
                  </button>
              </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                showAnswer(respondent);
              });
            }
          },
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "60vh",
      fixedColumnsLeft: 1,
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const isAnswerLoading = ref(false);
    const assessmentAnswer = ref([]);
    const isDetailsShowing = ref(false);
    const totalPercentage = ref(0);
    const totalYes = ref(0);
    const totalNo = ref(0);
    const totalCount = ref(0);

    const showAnswer = async (respondent) => {
      try {
        isDetailsShowing.value = true;
        isAnswerLoading.value = true;
        const res = await adminInstance.get(
          `/get/assessment-answer?id=${respondent.id}`
        );
        if (res.status === 200) {
          assessmentAnswer.value = res.data.answer;
          totalPercentage.value = res.data.totalPercentage;
          totalCount.value = res.data.totalCount;
          totalYes.value = res.data.totalYes;
          totalNo.value = res.data.totalNo;
        }
      } catch (err) {
        return err;
      } finally {
        isAnswerLoading.value = false;
      }
    };

    const closeDetails = () => {
      isDetailsShowing.value = false;
    };

    return {
      isLoading,
      respondents,
      hotSettings,
      dataPerPage,
      totalRespondentCount,
      totalPages,
      currentPage,
      onPageChanged,
      isAnswerLoading,
      assessmentAnswer,
      isDetailsShowing,
      closeDetails,
      totalPercentage,
      totalYes,
      totalNo,
      totalCount,
    };
  },
};
</script>
