<template>
  <AdminNavbar>
    <h1 class="text-lg font-bold">"Mining Week" арга хэмжээний илтгэгчид</h1>

    <div class="bg-white rounded shadow">
      <h6 class="text-base border-t-4 border-t-blue-500 rounded-t border-b p-3">
        Тэргүүлэх илтгэгчид
      </h6>

      <div class="p-3 space-y-5">
        <!-- PAGINATION -->
        <div class="flex justify-between items-center text-xs">
          <div class="space-x-3">
            <span>Арга хэмжээ:</span>
            <select
              name="selectedEvent"
              id="selectedEvent"
              class="border rounded p-1 text-blue-500 max-w-[20rem]"
              v-model="selectedEvent"
            >
              <option
                v-for="cat in events"
                :key="cat.id"
                :value="cat.id"
                class="text-pretty"
              >
                {{ cat.event }}
              </option>
            </select>
          </div>

          <div class="space-x-3">
            <span>Хуудас бүрт:</span>
            <select
              name="pagePerPage"
              id="pagePerPage"
              class="border rounded p-1 text-blue-500"
              v-model="dataPerPage"
            >
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <span>Нийт илтгэгчдийн тоо: {{ totalPresenterCount }} </span>

          <div class="flex items-center gap-3">
            <span>Нийт хуудас: {{ totalPages }} </span>
            <PaginationCart
              :totalPages="totalPages"
              :modelValue="currentPage"
              :style="{
                color: 'blue-500',
                height: 7,
                width: 7,
                fontSize: 'xs',
              }"
              @page-changed="onPageChanged"
            />
          </div>
        </div>

        <div>
          <div v-if="presenters.length > 0 && !isLoading" class="p-3">
            <hot-table :settings="hotSettings" :data="presenters"></hot-table>
          </div>
          <div
            v-else-if="presenters.length === 0 && !isLoading"
            class="flex justify-center items-center h-[60vh]"
          >
            Жагсаалт хоосон байна
          </div>

          <div v-else class="flex justify-center items-center h-[60vh]">
            <SpinLoading :color="'rgb(59 130 246)'" />
          </div>
        </div>

        <div ref="editDiv" class="rounded border mt-10">
          <h6 class="border-b p-3">Нэмэх / Засварлах</h6>

          <div class="flex p-3">
            <h4 class="w-[10rem] font-bold">
              Нүүр зураг<span class="text-red-500">*</span>
            </h4>
            <div class="space-y-3 w-full">
              <div
                class="flex flex-col justify-center items-center"
                :class="mainImage ? 'w-3/4' : 'h-40 w-40'"
              >
                <img
                  :src="imageSrc"
                  alt="image"
                  :class="!mainImage ? 'w-14 h-14' : ''"
                />
                <span v-if="!mainImage" class="text-xs text-gray-500"
                  >Зураг байхгүй байна</span
                >
              </div>
              <input
                type="file"
                class="w-full border rounded p-2"
                :class="
                  isSubmitted && !mainImage
                    ? 'border-red-500'
                    : 'border-gray-300'
                "
                @change="onImageChange"
                accept="image/jpeg, image/png"
              />
              <span class="text-sky-500 text-xs">
                Зөвхөн jpg, jpeg, png өргөтгөлтэй зураг оруулна уу.
              </span>
            </div>
          </div>

          <div class="grid grid-cols-2 gap-x-10 gap-y-3 text-sm p-3">
            <div class="flex items-center w-full gap-2">
              <label for="name_mn" class="text-nowrap font-bold"
                >Нэр /монголоор/<span class="text-red-500">*</span></label
              >
              <input
                id="name_mn"
                name="name_mn"
                type="text"
                placeholder="Нэр"
                class="border rounded p-2 w-full"
                :class="
                  isSubmitted && !form.name_mn
                    ? 'border-red-500'
                    : 'border-gray-300'
                "
                v-model="form.name_mn"
              />
            </div>
            <div class="flex items-center w-full gap-2">
              <label for="name_en" class="text-nowrap font-bold"
                >Нэр /англиар/<span class="text-red-500">*</span></label
              >
              <input
                id="name_en"
                name="name_en"
                type="text"
                placeholder="Name"
                class="border rounded p-2 w-full"
                :class="
                  isSubmitted && !form.name_en
                    ? 'border-red-500'
                    : 'border-gray-300'
                "
                v-model="form.name_en"
              />
            </div>
            <div class="flex items-center w-full gap-2">
              <label for="position_mn" class="text-nowrap font-bold"
                >Албан тушаал /монголоор/<span class="text-red-500"
                  >*</span
                ></label
              >
              <input
                id="position_mn"
                name="position_mn"
                type="text"
                placeholder="Албан тушаал"
                class="border rounded p-2 w-full"
                :class="
                  isSubmitted && !form.position_mn
                    ? 'border-red-500'
                    : 'border-gray-300'
                "
                v-model="form.position_mn"
              />
            </div>
            <div class="flex items-center w-full gap-2">
              <label for="position_en" class="text-nowrap font-bold"
                >Албан тушаал /англиар/<span class="text-red-500"
                  >*</span
                ></label
              >
              <input
                id="position_en"
                name="position_en"
                type="text"
                placeholder="Position"
                class="border rounded p-2 w-full"
                :class="
                  isSubmitted && !form.position_en
                    ? 'border-red-500'
                    : 'border-gray-300'
                "
                v-model="form.position_en"
              />
            </div>
            <div class="flex items-center w-full gap-2">
              <label for="position_en" class="text-nowrap font-bold"
                >Илтгэх арга хэмжээ<span class="text-red-500">*</span></label
              >
              <select
                name="event_id"
                id="event_id"
                class="border rounded p-2 w-full"
                :class="
                  isSubmitted && !form.event_id
                    ? 'border-red-500'
                    : 'border-gray-300'
                "
                v-model="form.event_id"
              >
                <option
                  v-for="cat in events"
                  :key="cat.id"
                  :value="cat.id"
                  class="text-pretty"
                >
                  {{ cat.event }}
                </option>
              </select>
            </div>
          </div>

          <div class="flex bg-gray-200 rounded gap-5 p-3 text-xs text-white">
            <button
              @click="uploadPresenter()"
              class="p-2"
              :class="
                form.id
                  ? 'bg-sky-500 hover:bg-sky-600'
                  : 'bg-[#28a745] hover:bg-[#208537]'
              "
            >
              <font-awesome-icon
                :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-add'"
                class="mr-1"
              />{{ form.id ? "Мэдээлэл засварлах" : "Мэдээлэл нэмэх" }}
            </button>

            <button
              @click="resetForm()"
              class="p-2 bg-orange-400 hover:bg-orange-500"
            >
              <font-awesome-icon
                icon="fa-solid fa-eraser"
                class="mr-1"
              />Арилгах
            </button>
          </div>
        </div>
      </div>
    </div>
  </AdminNavbar>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import adminMWInstance from "@/lib/adminMWInstance";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";

registerAllModules();

export default {
  name: "MWEventPresenters",
  components: {
    AdminNavbar,
    SpinLoading,
    PaginationCart,
    HotTable,
  },
  setup() {
    const isLoading = ref(false);
    const presenters = ref([]);
    const presenter = ref({});

    const dataPerPage = ref(20);
    const totalPresenterCount = ref(0);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const selectedEvent = ref(3);
    const events = ref([]);

    const fetchPresenters = async () => {
      try {
        isLoading.value = true;
        const res = await adminMWInstance.get("/get/event");
        if (res.status === 200) {
          events.value = res.data.event;
        }
        const preRes = await adminMWInstance.get(
          `/get/presenter?event=${selectedEvent.value}&page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        if (preRes.status === 200) {
          presenters.value = preRes.data.presenter;
          totalPresenterCount.value = preRes.data.total;
          totalPages.value = preRes.data.totalPages;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchPresenters();
    });

    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        if (presenter.value.profile_img) {
          imageSrc.value = presenter.value.profile_img;
          mainImage.value = presenter.value.profile_img;
        } else {
          imageSrc.value = defaultImage;
          mainImage.value = null;
        }
      }
    };

    const form = ref({
      id: null,
      name_mn: null,
      name_en: null,
      position_mn: null,
      position_en: null,
      event_id: 3,
    });

    const resetForm = () => {
      (imageSrc.value = defaultImage), (mainImage.value = null);
      form.value = {
        id: null,
        name_mn: null,
        name_en: null,
        position_mn: null,
        position_en: null,
        event_id: 3,
      };
    };

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchPresenters();
    };

    watch([() => dataPerPage.value, () => selectedEvent.value], () => {
      currentPage.value = 1;
      fetchPresenters();
    });

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const imageRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<div class="relative flex items-center justify-center p-2">
            <img src="${value}" alt="" class="w-28 object-fit" />
          </div>`;
    };

    const hotSettings = {
      colHeaders: [
        "Үйлдэл",
        "Дараалал",
        "Зураг",
        "Нэр",
        "Name",
        "Албан тушаал",
        "Position",
        "Илтгэл тавих арга хэмжээ",
        "Оруулсан огноо",
      ],
      colWidths: [200, 100, 150, 150, 150, 200, 200, 200, 150],
      columns: [
        {
          renderer: function (inst, td, row) {
            const presenter = presenters.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                  <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                      <svg class="fill-current w-3 h-3 mr-1" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                      <span>Засварлах</span>
                  </button>
                  <button class="flex justify-center items-center bg-red-500 py-1 px-2 rounded delete-btn">
                      <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                      <span>Устгах</span>
                  </button>
              </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                editPresenter(presenter);
              });
            }

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deletePresenter(presenter.id);
              });
            }
          },
        },
        {
          data: "presenter_queue",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "profile_img",
          readOnly: true,
          renderer: imageRenderer,
        },
        {
          data: "name_mn",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "name_en",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "position_mn",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "position_en",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "event",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "created_at",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "60vh",
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const editDiv = ref(null);
    const editPresenter = (presenter) => {
      imageSrc.value = presenter.profile_img;
      mainImage.value = presenter.profile_img;
      form.value = {
        id: presenter.id,
        name_mn: presenter.name_mn,
        name_en: presenter.name_en,
        position_mn: presenter.position_mn,
        position_en: presenter.position_en,
        event_id: presenter.event_id,
        presenter_queue: presenter.presenter_queue,
      };

      editDiv.value.scrollIntoView({ behavior: "smooth" });
    };

    const deletePresenter = async (id) => {
      if (!window.confirm(`Илтгэгчийн мэдээллийг устгах уу?`)) {
        return;
      }

      try {
        const res = await adminMWInstance.delete(`/delete/presenter?id=${id}`);
        if (res.status === 200) {
          await fetchPresenters();
        }
      } catch (err) {
        return err;
      }
    };

    const isSubmitted = ref(false);
    const validateForm = () => {
      return (
        mainImage.value &&
        form.value.name_en &&
        form.value.name_mn &&
        form.value.position_en &&
        form.value.position_mn &&
        form.value.event_id
      );
    };

    const uploadPresenter = async () => {
      try {
        isSubmitted.value = true;
        if (!validateForm()) {
          return;
        }

        const formData = new FormData();
        formData.append("image", mainImage.value);
        formData.append("name_mn", form.value.name_mn);
        formData.append("name_en", form.value.name_en);
        formData.append("position_mn", form.value.position_mn);
        formData.append("position_en", form.value.position_en);
        formData.append("event_id", form.value.event_id);

        if (form.value.id) {
          formData.append("id", form.value.id);
          formData.append("presenter_queue", form.value.presenter_queue);
          const res = await adminMWInstance.post("/update/presenter", formData);
          if (res.status === 200) {
            isSubmitted.value = false;
            resetForm();
            await fetchPresenters();
          }
        } else {
          const res = await adminMWInstance.post("/insert/presenter", formData);
          if (res.status === 200) {
            isSubmitted.value = false;
            resetForm();
            await fetchPresenters();
          }
        }
      } catch (err) {
        return err;
      }
    };

    return {
      isLoading,
      presenters,
      form,
      resetForm,
      uploadPresenter,
      imageSrc,
      mainImage,
      onImageChange,
      onPageChanged,
      dataPerPage,
      currentPage,
      totalPresenterCount,
      totalPages,
      events,
      isSubmitted,
      validateForm,
      selectedEvent,
      hotSettings,
      editDiv,
    };
  },
};
</script>
