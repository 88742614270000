<template>
  <MW2023Background v-if="!isLoading" class="text-sm text-white">
    <section class="relative w-full overflow-hidden text-sm text-white">
      <div class="relative w-screen h-[80vh]">
        <iframe
          src="https://www.youtube.com/embed/5YW9LLdbU0U?autoplay=1&mute=1&loop=1&playlist=5YW9LLdbU0U&controls=0&modestbranding=1&iv_load_policy=3&rel=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          class="absolute top-[-15%] left-[-10%] w-[120%] h-[135%] overflow-hidden"
        ></iframe>
      </div>
      <div
        class="absolute bottom-0 left-0 h-1/2 w-full bg-gradient-to-t from-[#003d31] to-transparent"
      ></div>
      <div class="overlay absolute top-0 left-0 w-full h-full"></div>
      <div
        class="absolute top-0 left-0 flex justify-center items-center w-full h-full"
      >
        <div class="w-full min-[1200px]:w-4/5 max-[1199px]:px-5">
          <h3 class="text-base sm:text-xl md:text-2xl lg:text-3xl mb-3">
            Ulaanbaatar, Mongolia Oct 02-05
          </h3>
          <h1 class="font-bold text-2xl md:text-4xl lg:text-8xl uppercase">
            Mining Week 2024
          </h1>
        </div>
      </div>
    </section>

    <!-- 2 buttons -->
    <MW2023Form class="my-32" />

    <!-- Portfolio -->
    <section class="relative flex justify-center py-20">
      <div class="w-full min-[1200px]:w-4/5 max-[1199px]:px-5">
        <!-- navigations -->
        <div class="flex max-sm:flex-col justify-center items-center gap-3">
          <button
            @click="setFilter('all')"
            class="px-6 py-2 border uppercase"
            :class="filter === 'all' ? 'bg-[#00a375]' : 'hover:bg-[#00a375]'"
          >
            {{ lang === "mn" ? "Бүгд" : "ALL" }}
          </button>
          <button
            @click="setFilter('Mining week 2022')"
            class="px-6 py-2 border uppercase"
            :class="
              filter === 'Mining week 2022'
                ? 'bg-[#00a375]'
                : 'hover:bg-[#00a375]'
            "
          >
            Miningweek 2022
          </button>
          <button
            @click="setFilter('Mining week 2023')"
            class="px-6 py-2 border uppercase"
            :class="
              filter === 'Mining week 2023'
                ? 'bg-[#00a375]'
                : 'hover:bg-[#00a375]'
            "
          >
            Miningweek 2023
          </button>
        </div>

        <!-- gallery -->
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-around gap-8 mt-10"
        >
          <div
            v-for="(portfolio, index) in filteredPortfolio"
            :key="index"
            class="group relative rounded-xl"
            @mouseenter="showOverlay()"
            @mouseleave="showOverlay()"
            v-motion-pop-visible-once
            :duration="300"
          >
            <img :src="portfolio.image" :alt="index" class="rounded-xl" />
            <Transition name="slide-fade">
              <div
                v-if="isOverlay"
                class="invisible group-hover:visible absolute top-0 left-0 w-full h-full p-5"
              >
                <div
                  class="relative flex flex-col justify-end bg-[#003d31] bg-opacity-80 w-full h-full text-white rounded-xl p-5"
                >
                  <button
                    v-motion-slide-visible-right
                    :delay="100"
                    :duration="300"
                    class="absolute top-5 right-5 flex justify-center items-center p-2 border rounded-full"
                  >
                    <font-awesome-icon icon="fa-regular fa-image" />
                  </button>
                  <h1
                    v-motion-slide-visible-bottom
                    :delay="100"
                    :duration="300"
                    class="text-base"
                  >
                    {{ portfolio.title }}
                  </h1>
                  <h5
                    v-motion-slide-visible-bottom
                    :delay="100"
                    :duration="300"
                    class="mt-3 font-bold text-xl"
                  >
                    {{ portfolio.description }}
                  </h5>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </section>

    <!-- News -->
    <section class="relative flex justify-center items-center">
      <div class="w-full min-[1200px]:w-4/5 max-[1199px]:px-5 my-20">
        <h1
          v-motion-slide-visible-once-bottom
          class="font-bold text-center uppercase text-lg"
        >
          {{ lang === "mn" ? "Мэдээ мэдээлэл" : "Latest news" }}
        </h1>
        <div
          v-motion-slide-visible-once-bottom
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-10"
        >
          <div
            v-for="(item, index) in news"
            :key="index"
            class="shadow-[0px_16px_32px_rgb(0_0_0/44%)]"
          >
            <img
              :src="item.image"
              :alt="index"
              class="w-full h-[15rem] object-cover object-center"
            />
            <div class="bg-[#003d31] p-5 space-y-2 md:space-y-4">
              <h4 class="text-xs">{{ item.publisher }}</h4>
              <h1 class="line-clamp-1 text-lg">{{ item.title }}</h1>
              <button class="flex items-center hover:text-[#00a375]">
                <span> {{ lang === "mn" ? "Дэлгэрэнгүй" : "Read more" }} </span
                ><font-awesome-icon
                  icon="fa-solid fa-arrow-right-long"
                  class="ml-2 text-xs"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Organizers -->
    <section class="relative flex justify-center items-center pt-10 pb-40">
      <div
        class="flex flex-col justify-center items-center w-full min-[1200px]:w-4/5"
      >
        <h1 class="font-bold text-center uppercase text-lg">
          {{ lang === "mn" ? "Зохион байгуулагч" : "Organizer" }}
        </h1>
        <div
          class="flex max-sm:flex-col max-sm:items-center max-sm:space-y-5 sm:space-x-10 mt-10"
        >
          <img
            src="@/assets/images/miningWeek/spons-logo-1.png"
            alt="logo"
            class="w-[15rem] object-fit"
          />
          <img
            src="@/assets/images/miningWeek/spons-logo-2.png"
            alt="logo"
            class="w-[15rem] object-fit"
          />
        </div>
      </div>
    </section>
  </MW2023Background>

  <MW2023OverlayLoading v-else />
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import MW2023OverlayLoading from "@/components/MiningWeek/MW2023/MW2023OverlayLoading.vue";
import MW2023Background from "@/components/MiningWeek/MW2023/MW2023Background.vue";
import MW2023Form from "./MW2023Form";

export default {
  name: "MW2023HomePage",
  components: {
    MW2023OverlayLoading,
    MW2023Background,
    MW2023Form,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const isLoading = ref(false);

    const fetchData = () => {
      try {
        isLoading.value = true;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchData();
    });

    //GALLERY
    const portfolio = [
      {
        title: "Mining week 2023",
        description: "Mining week 2023",
        image: require("@/assets/images/miningWeek/week-1.jpeg"),
      },
      {
        title: "Mining week 2023",
        description: "Mining week 2023",
        image: require("@/assets/images/miningWeek/week-2.jpeg"),
      },
      {
        title: "Mining week 2023",
        description: "Mining week 2023",
        image: require("@/assets/images/miningWeek/week-3.jpeg"),
      },
      {
        title: "Mining week 2022",
        description: "Mining week 2022",
        image: require("@/assets/images/miningWeek/week-4.jpeg"),
      },
      {
        title: "Mining week 2022",
        description: "Mining week 2022",
        image: require("@/assets/images/miningWeek/week-5.jpeg"),
      },
      {
        title: "Mining week 2022",
        description: "Mining week 2022",
        image: require("@/assets/images/miningWeek/week-6.jpeg"),
      },
    ];

    const filter = ref("all");
    const setFilter = (selectedFilter) => {
      filter.value = selectedFilter;
    };

    const filteredPortfolio = computed(() => {
      if (filter.value === "all") {
        return portfolio;
      }
      return portfolio.filter((item) => item.title === filter.value);
    });

    const isOverlay = ref(false);
    const showOverlay = () => {
      isOverlay.value = !isOverlay.value;
    };

    const isImages = ref(false);
    const openImages = () => {
      isImages.value = !isImages.value;
    };

    //NEWS
    const news = [
      {
        title: "Салбар хурлууд",
        publisher: "By Admin, 07 Oct, 2023",
        image: require("@/assets/images/miningWeek/news/news-1.png"),
      },
      {
        title: "Mining Week 2023",
        publisher: "By Admin, 21 Sep, 2023",
        image: require("@/assets/images/miningWeek/news/news-2.png"),
      },
      {
        title: "2023 оны 10 дугаар сарын 09-өөс 13-ны хооронд",
        publisher: "By Admin, 21 Sep, 2023",
        image: require("@/assets/images/miningWeek/news/news-3.png"),
      },
    ];

    return {
      lang,
      isLoading,
      filter,
      setFilter,
      filteredPortfolio,
      news,
      isOverlay,
      showOverlay,
      isImages,
      openImages,
    };
  },
};
</script>

<style scoped>
.overlay {
  background-image: url("@/assets/images/miningWeek/diagonalstripe-black.png");
  opacity: 0.6;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-up-enter-active {
  transition: all 2s ease-out;
}

.slide-up-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
