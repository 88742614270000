<template>
  <div v-if="!isLoading && noticeData.length > 0" class="mt-10">
    <div
      class="flex max-sm:flex-col max-sm:gap-2 justify-between items-center max-sm:items-start text-sm mb-5"
    >
      <div class="space-x-3">
        <span>{{ lang === "mn" ? "Хуудас бүрт:" : "Per page:" }}</span>
        <select
          name="dataPerPage"
          id="dataPerPage"
          class="border rounded p-1 text-blue-500"
          v-model="dataPerPage"
        >
          <option value="12">12</option>
          <option value="24">24</option>
          <option value="50">50</option>
        </select>
      </div>

      <span
        >{{ lang === "mn" ? "Нийт арилжааны тоо:" : "Total Trades:" }}
        {{ totalNoticeCount }}
      </span>

      <div class="flex items-center gap-3">
        <span
          >{{ lang === "mn" ? "Нийт хуудас:" : "Pages:" }} {{ totalPages }}
        </span>
        <PaginationCart
          :totalPages="totalPages"
          :modelValue="currentPage"
          :style="{
            color: 'blue-500',
            height: 7,
            width: 7,
            fontSize: 'xs',
          }"
          @page-changed="onPageChanged"
        />
      </div>
    </div>

    <div
      class="grid grid-cols-1 min-[500px]:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-5"
    >
      <div
        v-for="data in noticeData"
        :key="data.id"
        class="block w-full rounded-xl shadow-lg p-3"
      >
        <span class="flex items-center w-full gap-3">
          <div
            class="flex justify-center items-center bg-gray-100 w-1/4 text-gray-500 h-8 w-12 rounded-full"
          >
            <font-awesome-icon icon="fa-solid fa-calendar-days" />
          </div>
          <span class="block">
            <p class="font-bold text-black text-sm">
              {{ data.seller_name }}/{{ data.order_num }}
            </p>
            <p class="font-thin text-gray-500 text-xs">{{ data.type }}</p>
          </span>
        </span>

        <div class="flex items-center gap-3 mt-3">
          <p class="text-xs text-center text-[#4BB543] w-full">
            {{ data.sell_date }}
          </p>
          <p class="text-xs text-center w-full">
            {{ data.quantity }}{{ data.currency }} <br />
            /{{ `${data.quantity * data.lot_size} тонн` }}/
          </p>
        </div>

        <div class="flex justify-center w-full mt-3">
          <a
            :href="data.own_url ? data.own_url : data.other"
            target="_blank"
            class="border border-gray-300 p-2 text-center text-gray-500 rounded text-xs hover:border-gray-500 hover:text-gray-700"
            >{{ lang === "mn" ? "Дэлгэрэнгүй" : "See more" }}</a
          >
        </div>
      </div>
    </div>
  </div>

  <div
    v-else-if="!isLoading && noticeData.length === 0"
    class="flex justify-center items-center h-[40vh]"
  >
    {{ lang === "mn" ? "Өгөгдөл олдсонгүй." : "No data was found" }}
  </div>

  <div v-else-if="isLoading" class="flex justify-center items-center h-[50vh]">
    <SpinLoading :color="'rgb(59 130 246)'" />
  </div>
</template>

<script>
import clientInstance from "@/lib/clientInstance";
import { onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import PaginationCart from "@/components/ui/PaginationCart.vue";

export default {
  name: "MMNoticeData",
  components: {
    SpinLoading,
    PaginationCart,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const isLoading = ref(false);
    const noticeData = ref([]);

    const dataPerPage = ref(12);
    const totalNoticeCount = ref(0);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const fetchNoticeData = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get(
          `/get/notice?page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        if (res.status === 200) {
          noticeData.value = res.data.notice;
          totalPages.value = res.data.totalPages;
          totalNoticeCount.value = res.data.total;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchNoticeData();
    });

    watch([dataPerPage], () => {
      currentPage.value = 1;
      fetchNoticeData();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchNoticeData();
    };

    return {
      lang,
      isLoading,
      dataPerPage,
      totalPages,
      totalNoticeCount,
      currentPage,
      onPageChanged,
      noticeData,
    };
  },
};
</script>
