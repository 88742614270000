<template>
  <div class="divide-x divide-white">
    <img
      src="@/assets/images/miningWeek2024/mw-logo/miningweek 2024-02.png"
      alt="miningweek"
      class="h-8 sm:h-12 lg:h-16 object-contain mr-5"
    />
    <img
      src="@/assets/images/miningWeek2024/mw-logo/MINEPRO 2024 logo_Minepro logo white.png"
      alt="minepro"
      class="h-10 sm:h-14 lg:h-20 object-contain"
    />
  </div>
</template>

<script>
export default {
  name: "MwOrganiserLogos",
};
</script>
