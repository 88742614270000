<template>
  <main
    class="relative flex flex-col justify-center items-center bg-[#001F3D] w-full h-full z-0"
  >
    <div
      class="absolute top-0 left-0 flex justify-center items-center w-full h-full pt-60 pb-10"
    >
      <div class="background-overlay w-4/5 h-full"></div>
    </div>
    <div class="overlay absolute top-0 left-0 w-full h-full"></div>
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: "MWBackground",
};
</script>

<style>
.background-overlay {
  background-image: url("@/assets/images/miningWeek2024/background-7.png");
  background-repeat: no-repeat;
}

.overlay {
  background-image: url("@/assets/images/miningWeek/diagonalstripe-black.png");
}
</style>
