<template>
  <MW2023Background>
    <div
      class="relative min-h-[80vh] flex flex-col justify-center items-center gap-3 text-white"
    >
      <font-awesome-icon
        icon="fa-solid fa-screwdriver-wrench"
        class="text-8xl"
      />
      <p>Уучлаарай, хуудас түр засвартай байна.</p>
    </div>
  </MW2023Background>
</template>

<script>
import MW2023Background from "@/components/MiningWeek/MW2023/MW2023Background.vue";

export default {
  name: "MW2023Presentation",
  components: {
    MW2023Background,
  },
  setup() {
    return {};
  },
};
</script>
