<template>
  <MWBackground>
    <section
      class="flex justify-center w-full min-h-[150vh] py-10 text-white z-10"
    >
      <div class="w-full min-[1200px]:w-4/5 max-[1199px]:px-5">
        <MWOrganiserLogos class="flex justify-start items-center w-full" />
        <h1 class="font-bold text-xl sm:text-2xl uppercase text-center mt-5">
          {{ lang === "mn" ? "Бүртгэл" : "Registration" }}
        </h1>
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-between gap-x-4 lg:gap-x-8 gap-y-5 mt-10 px-5"
        >
          <div class="flex items-center gap-3 max-md:text-sm">
            <label
              for="company"
              class="w-1/3 text-end"
              :class="!form.company && isSubmitted ? 'text-red-500' : ''"
              >{{ lang === "mn" ? "Байгууллага:" : "Company:" }}</label
            >
            <input
              name="company"
              id="company"
              type="text"
              class="w-2/3 border p-2 bg-white text-black"
              :class="
                !form.company && isSubmitted ? 'border-red-500' : 'border-white'
              "
              v-model="form.company"
            />
          </div>
          <div class="flex items-center gap-3">
            <label
              for="first_name"
              class="w-1/3 text-end"
              :class="!form.first_name && isSubmitted ? 'text-red-500' : ''"
              >{{ lang === "mn" ? "Нэр:" : "First Name:" }}</label
            >
            <input
              name="first_name"
              id="first_name"
              type="text"
              class="w-2/3 border p-2 bg-white text-black"
              :class="
                !form.first_name && isSubmitted
                  ? 'border-red-500'
                  : 'border-white'
              "
              v-model="form.first_name"
            />
          </div>
          <div class="flex items-center gap-3">
            <label
              for="last_name"
              class="w-1/3 text-end"
              :class="!form.last_name && isSubmitted ? 'text-red-500' : ''"
              >{{ lang === "mn" ? "Овог:" : "Last Name:" }}</label
            >
            <input
              name="last_name"
              id="last_name"
              type="text"
              class="w-2/3 border p-2 bg-white text-black"
              :class="
                !form.last_name && isSubmitted
                  ? 'border-red-500'
                  : 'border-white'
              "
              v-model="form.last_name"
            />
          </div>

          <div class="flex items-center gap-3">
            <label
              for="country"
              class="w-1/3 text-end"
              :class="!form.country && isSubmitted ? 'text-red-500' : ''"
              >{{ lang === "mn" ? "Улс:" : "Country:" }}</label
            >
            <country-select
              id="country"
              :country="form.country"
              topCountry="MN"
              :placeholder="lang === 'mn' ? 'Улс сонгох' : 'Select Country'"
              class="w-2/3 border p-2 bg-white text-black"
              :class="
                !form.country && isSubmitted ? 'border-red-500' : 'border-white'
              "
              v-model="form.country"
            />
          </div>
          <div class="flex items-center gap-3">
            <label
              for="email"
              class="w-1/3 text-end"
              :class="!form.email && isSubmitted ? 'text-red-500' : ''"
              >{{ lang === "mn" ? "И-мэйл:" : "E-mail:" }}</label
            >
            <input
              name="email"
              id="email"
              type="email"
              class="w-2/3 border p-2 bg-white text-black"
              :class="
                !form.email && isSubmitted ? 'border-red-500' : 'border-white'
              "
              v-model="form.email"
            />
          </div>
          <div class="flex items-center gap-3">
            <label
              for="phone_number"
              class="w-1/3 text-end"
              :class="!form.phone_number && isSubmitted ? 'text-red-500' : ''"
              >{{ lang === "mn" ? "Утас:" : "Phone Number:" }}</label
            >
            <input
              name="phone_number"
              id="phone_number"
              type="tel"
              class="w-2/3 border p-2 bg-white text-black"
              :class="
                !form.phone_number && isSubmitted
                  ? 'border-red-500'
                  : 'border-white'
              "
              v-model="form.phone_number"
            />
          </div>
        </div>

        <p
          class="flex items-center gap-2 text-xs sm:text-sm md:text-base mt-5 px-5"
        >
          <font-awesome-icon
            icon="fa-solid fa-triangle-exclamation"
            class="text-amber-500"
          />
          {{
            lang === "mn"
              ? " Арга хэмжээний дэлгэрэнгүй хөтөлбөр гарах үед бид таны бүртгүүлсэн и-мэйл хаягаар хүргүүлэх болно."
              : "When the detailed program of the event is finalized, we will send it to you via the email address you registered with."
          }}
        </p>

        <p
          v-if="isSubmitted && !validateForm()"
          class="text-center text-amber-500 mt-7"
        >
          {{
            lang === "mn"
              ? "Уучлаарай, бүртгэл хэсгийг бүрэн бөглөнө үү."
              : "Sorry, please complete the registration section."
          }}
        </p>

        <div
          class="flex justify-center gap-3"
          :class="validateForm() || !isSubmitted ? 'mt-7' : 'mt-3'"
        >
          <button
            v-if="!success && !isLoading"
            @click="submitForm()"
            class="w-1/2 md:w-1/3 p-2 text-center bg-[#009269] hover:bg-[#00a375]"
          >
            {{ lang === "mn" ? "Бүртгүүлэх" : "Register" }}
          </button>

          <div
            v-else-if="!success && isLoading"
            class="w-1/2 md:w-1/3 p-2 border border-white flex justify-center items-center"
          >
            <SingleSpinLoading />
          </div>

          <div
            v-else-if="success && !isLoading"
            class="relative flex items-center gap-2 bg-white shadow shadow-white p-2 text-black font-bold"
          >
            <font-awesome-icon
              icon="fa-solid fa-check"
              class="text-[#4BB543] text-base"
            />
            {{
              lang === "mn"
                ? "Бүртгэл амжилттай хийгдлээ."
                : "Registration successful."
            }}
            <div class="absolute h-1 bottom-0 bg-[#4BB543] animate-line"></div>
          </div>
        </div>
      </div>
    </section>
  </MWBackground>
</template>

<script>
import clientMWInstance from "@/lib/clientMWInstance";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import MWBackground from "@/components/MiningWeek/MW2024/MWBackground.vue";
import MWOrganiserLogos from "@/components/MiningWeek/MW2024/MWOrganiserLogos.vue";
import SingleSpinLoading from "@/components/MiningWeek/SingleSpinLoading";

export default {
  name: "MWForm",
  components: {
    MWBackground,
    MWOrganiserLogos,
    SingleSpinLoading,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(false);
    const success = ref(false);
    const isSubmitted = ref(false);
    const form = ref({
      company: null,
      first_name: null,
      last_name: null,
      country: null,
      email: null,
      phone_number: null,
    });

    const validateForm = () => {
      return (
        form.value.company &&
        form.value.first_name &&
        form.value.last_name &&
        form.value.country &&
        form.value.email &&
        form.value.phone_number
      );
    };

    const resetForm = () => {
      form.value = {
        company: null,
        first_name: null,
        last_name: null,
        country: null,
        email: null,
        phone_number: null,
      };
    };

    const submitForm = async () => {
      isSubmitted.value = true;

      if (!validateForm()) {
        return;
      }

      try {
        isLoading.value = true;
        const res = await clientMWInstance.post(
          "/insert/registration",
          form.value
        );
        if (res.status === 200) {
          isSubmitted.value = false;
          success.value = true;
          resetForm();

          setTimeout(() => {
            success.value = false;
          }, 10000);
        }
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    return {
      lang,
      form,
      isLoading,
      submitForm,
      success,
      isSubmitted,
      validateForm,
    };
  },
};
</script>

<style scoped>
@keyframes lineAnimation {
  from {
    width: 0%;
  }
  to {
    width: 95%;
  }
}

.animate-line {
  animation: lineAnimation 9s linear forwards;
}
</style>
