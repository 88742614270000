<template>
  <MWBackground>
    <div class="flex justify-center w-full py-10 z-10">
      <main
        class="min-h-screen w-full min-[1200px]:w-4/5 max-[1199px]:px-5 text-white"
      >
        <MWOrganiserLogos class="flex justify-start items-center w-full" />
        <h1 class="font-bold text-xl sm:text-2xl uppercase mt-5">
          {{ lang === "mn" ? "Тэргүүлэх илтгэгчид" : "Key speakers" }}
        </h1>

        <div
          v-if="presenters.length > 0 && !isLoading"
          class="grid grid-cols-2 min-[400px]:grid-cols-3 min-[650px]:grid-cols-4 md:grid-cols-5 min-[900px]:grid-cols-6 min-[1200px]:grid-cols-8 gap-3 md:gap-5 mt-5 mb-20"
        >
          <div
            v-for="speaker in presenters"
            :key="speaker.id"
            v-motion-pop-visible-once
          >
            <img
              v-if="speaker.profile_img"
              :src="speaker.profile_img"
              alt="profile"
              class="h-36 w-full object-cover object-top"
            />
            <div v-else class="bg-white h-36 w-full"></div>
            <h6
              class="uppercase text-xs font-bold mt-1 max-[1200px]:text-center"
            >
              {{ lang === "mn" ? speaker.name_mn : speaker.name_en }}
            </h6>
            <h6 class="text-[0.65rem] font-thin mt-1">
              {{ lang === "mn" ? speaker.position_mn : speaker.position_en }}
            </h6>
          </div>
        </div>

        <div
          v-else-if="presenters.length === 0 && !isLoading"
          class="flex justify-center items-center mt-20 lg:mt-40"
        >
          {{
            lang === "mn"
              ? "Уучлаарай, илтгэгчидийн мэдээлэл хараахан гараагүй байна."
              : "Sorry, speaker information is not available yet."
          }}
        </div>

        <div v-else class="flex justify-center items-center mt-20 lg:mt-40">
          <BarLoading />
        </div>
      </main>
    </div>
  </MWBackground>
</template>

<script>
import MWBackground from "@/components/MiningWeek/MW2024/MWBackground.vue";
import MWOrganiserLogos from "@/components/MiningWeek/MW2024/MWOrganiserLogos";
import BarLoading from "@/components/ui/BarLoading.vue";
import clientMWInstance from "@/lib/clientMWInstance";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "MWPresentation",
  components: {
    MWBackground,
    MWOrganiserLogos,
    BarLoading,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(false);
    const presenters = ref([]);

    const fetchPresenters = async () => {
      try {
        isLoading.value = true;
        const res = await clientMWInstance.get(`/get/presenter?id=${3}`);
        if (res.status === 200) {
          presenters.value = res.data.presenter;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchPresenters();
    });

    return { lang, isLoading, presenters };
  },
};
</script>
